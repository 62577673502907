<template>
  <main>
    <!-- ===== Hero ===== -->
    <div class="gradient-x-overlay-sm-indigo position-relative overflow-hidden">
      <div class="container space-top-3 space-bottom-2">
        <div class="w-lg-75 text-center mx-lg-auto">
          <span class="d-block small font-weight-bold text-cap mb-2">Developer Portals for API Economy</span>
          <h1 class="mb-3">Ready Made API Developer Portal Powered by OpenAPIHub</h1>
          <p class="lead">Create your branded API Developer Portal with ease</p>
          <a id="ga-P02b-02-04" class="btn btn-primary transition-3d-hover mt-3" href="https://hub.openapihub.com/" target="_blank">Generate Now for FREE</a>
        </div>
        <div class="position-relative w-90 w-md-75 mx-auto mt-6">
          <div class="position-relative z-index-2" data-aos="fade-up" data-aos-offset="-50">
            <img class="img-fluid rounded-lg" src="@/assets/img/products/developer-portal/dp-endpoint.svg" alt="OpenAPIHub Developer Portal">
          </div>
          <figure class="max-w-15rem w-100 position-absolute top-0 right-0" data-aos="fade-up" data-aos-delay="100" data-aos-offset="-50">
            <div class="mt-n11 mr-n11">
              <img class="img-fluid" src="@/assets/svg/components/dots-4.svg">
            </div>
          </figure>
          <figure class="max-w-15rem w-100 position-absolute bottom-0 left-0" data-aos="fade-up">
            <div class="mb-n7 ml-n7">
              <img class="img-fluid" src="@/assets/svg/components/dots-3.svg">
            </div>
          </figure>
        </div>
      </div>
      <figure class="w-35 position-absolute top-0 right-0 z-index-n1 mt-n11 mr-n11">
        <img class="img-fluid" src="@/assets/svg/components/half-circle-1.svg">
      </figure>
    </div>
    <!-- ===== End Hero ===== -->

    <!-- ===== Features ===== -->
    <div class="container space-2 space-lg-3">
      <!-- Feature 1 -->
      <div class="row align-items-lg-center mb-11">
        <div class="col-lg-6">
          <div class="mb-3 mb-lg-0">
            <h2 class="mb-3">Build up Your Presence in API Era</h2>
            <p>A developer portal is key to help businesses understand the value of your APIs and provide the essential resources for developers to start using APIs with ease.</p>
            <h5 class="mb-3">Popular Features</h5>
            <div class="media text-body font-size-1 mb-3">
              <i class="fas fa-star text-warning mt-1 mr-2" />
              <div class="media-body">
                Rich Interactive Documents
              </div>
            </div>
            <div class="media text-body font-size-1 mb-3">
              <i class="fas fa-star text-warning mt-1 mr-2" />
              <div class="media-body">
                API Catalogue &amp; Pricing Support
              </div>
            </div>
            <div class="media text-body font-size-1 mb-3">
              <i class="fas fa-star text-warning mt-1 mr-2" />
              <div class="media-body">
                API Partner Management
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6" data-aos="fade-left">
          <div class="w-90 w-md-75 w-lg-100 mx-auto">
            <div class="position-relative shadow-lg rounded-lg">
              <img class="img-fluid rounded-lg" src="@/assets/img/products/developer-portal/dp-homepage.svg" alt="Build up Your Presence in API Era">
            </div>
          </div>
        </div>
      </div>
      <!-- Feature 2 Desktop view only-->
      <div class="row align-items-lg-center d-none d-lg-flex mb-11">
        <div class="col-lg-7 col-xl-6 order-lg-2 mb-6 mb-lg-0">
          <h2 class="mb-3">Build Interactive Documentation</h2>
          <p>Provide up-to-date interactive documents across teams in a single view. No more PDF, Spreadsheet sharing of API Specifications.</p>
          <ul class="nav nav-box" role="tablist">
            <li class="nav-item w-100 mx-0 mb-3">
              <a id="fiber-payment-1-tab" class="nav-link p-4 active" data-toggle="pill" href="#fiber-payment-1" role="tab" aria-controls="fiber-payment-1" aria-selected="true">
                <div class="media">
                  <figure class="w-100 max-w-6rem mr-4">
                    <img class="img-fluid" src="@/assets/svg/icons/icon-12.svg">
                  </figure>
                  <div class="media-body">
                    <h4 class="mb-0">Guides</h4>
                    <div class="mt-2">
                      <p class="text-body mb-0">Use of Markdown Editor to build your own beautiful docs, landing pages, and more.</p>
                    </div>
                  </div>
                </div>
              </a>
            </li>
            <li class="nav-item w-100 mx-0 mb-3">
              <a id="fiber-payment-2-tab" class="nav-link p-4" data-toggle="pill" href="#fiber-payment-2" role="tab" aria-controls="fiber-payment-2" aria-selected="false">
                <div class="media">
                  <figure class="w-100 max-w-6rem mr-4">
                    <img class="img-fluid" src="@/assets/svg/icons/icon-44.svg">
                  </figure>
                  <div class="media-body">
                    <h4 class="mb-0">Try-it-out NOW</h4>
                    <div class="mt-2">
                      <p class="text-body mb-0">Allow your users to test the real code samples with API keys in our secured sandbox.</p>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-lg-5 col-xl-6 order-lg-1 align-self-lg-center">
          <div class="tab-content pr-lg-4">
            <div id="fiber-payment-1" class="tab-pane fade show active" role="tabpanel" aria-labelledby="fiber-payment-1-tab">
              <div class="shadow-lg rounded-lg">
                <img class="img-fluid rounded-lg" src="@/assets/img/products/developer-portal/dp-guides.svg" alt="Guides">
              </div>
            </div>
            <div id="fiber-payment-2" class="tab-pane fade" role="tabpanel" aria-labelledby="fiber-payment-2-tab">
              <div class="shadow-lg rounded-lg">
                <img class="img-fluid rounded-lg" src="@/assets/img/products/developer-portal/dp-endpoint.svg" alt="Try-it-out NOW">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Feature 2 Mobile & tablet view only -->
      <div class="d-lg-none mb-11">
        <div class="mb-3">
          <h2 class="mb-3">Build Interactive Documentation</h2>
          <p>Provide up-to-date interactive documents across teams in a single view. No more PDF, Spreadsheet sharing of API Specifications.</p>
        </div>
        <div class="mb-6">
          <div class="media mb-3">
            <figure class="w-100 max-w-6rem mr-4">
              <img class="img-fluid" src="@/assets/svg/icons/icon-12.svg">
            </figure>
            <div class="media-body">
              <h4 class="mb-0">Guides</h4>
              <div class="mt-2">
                <p class="text-body mb-0">Use of Markdown Editor to build your own beautiful docs, landing pages, and more.</p>
              </div>
            </div>
          </div>
          <div class="w-90 w-md-75 mx-auto">
            <div class="shadow-lg rounded-lg">
              <img class="img-fluid rounded-lg" src="@/assets/img/products/developer-portal/dp-guides.svg" alt="Guides">
            </div>
          </div>
        </div>
        <div class="mb-6">
          <div class="media mb-3">
            <figure class="w-100 max-w-6rem mr-4">
              <img class="img-fluid" src="@/assets/svg/icons/icon-44.svg">
            </figure>
            <div class="media-body">
              <h4 class="mb-0">Try-it-out NOW</h4>
              <div class="mt-2">
                <p class="text-body mb-0">Allow your users to test the real code samples with API keys in our secured sandbox.</p>
              </div>
            </div>
          </div>
          <div class="w-90 w-md-75 mx-auto">
            <div class="shadow-lg rounded-lg">
              <img class="img-fluid rounded-lg" src="@/assets/img/products/developer-portal/dp-endpoint.svg" alt="Try-it-out NOW">
            </div>
          </div>
        </div>
      </div>
      <!-- Feature 3 -->
      <div class="row align-items-lg-center">
        <div class="col-lg-6">
          <div class="mb-3 mb-lg-0">
            <h2 class="mb-3">API Catalogue &amp; Pricing</h2>
            <p>Configure your own API catalogue with ease and define the associated API pricing and subscription plan.</p>
            <h5 class="mb-3">What you can do with your Developer Portal</h5>
            <div class="media text-body font-size-1 mb-3">
              <i class="fas fa-star text-warning mt-1 mr-2" />
              <div class="media-body">
                Support of OpenAPI / Swagger Specification
              </div>
            </div>
            <div class="media text-body font-size-1 mb-3">
              <i class="fas fa-star text-warning mt-1 mr-2" />
              <div class="media-body">
                API Pricing and Subscription Management
              </div>
            </div>
            <div class="media text-body font-size-1 mb-3">
              <i class="fas fa-star text-warning mt-1 mr-2" />
              <div class="media-body">
                API Metrics and Analytics
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6" data-aos="fade-left">
          <div class="w-90 w-md-75 w-lg-100 mx-auto">
            <div class="shadow-lg rounded-lg">
              <img class="img-fluid rounded-lg" src="@/assets/img/products/developer-portal/dp-apis.svg" alt="OpenAPIHub Catalogue">
            </div>
            <div class="position-absolute bottom-0 left-0 max-w-40rem mx-auto ml-auto mb-n5 w-65" data-aos="fade-up" data-aos-delay="100">
              <div class="shadow-lg rounded-lg">
                <img class="img-fluid rounded-lg" src="@/assets/img/products/developer-portal/dp-pricing.svg" alt="OpenAPIHub Pricing">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ===== End Features ===== -->

    <!-- ===== CTA Section ===== -->
    <div class="gradient-x-overlay-sm-indigo text-center bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-about-hero.svg') + ')'}">
      <div class="container space-2">
        <div class="mb-6">
          <h2>Get started with OpenAPIHub API Developer Portal today</h2>
          <p>Create interactive API documentation and connect with thousands of developers.</p>
        </div>
        <a id="ga-P02b-06-03" class="btn btn-primary transition-3d-hover" href="https://hub.openapihub.com/" target="_blank">Generate Now for FREE</a>
      </div>
    </div>
    <!-- ===== End CTA Section ===== -->

    <!-- ===== Other Products Section ===== -->
    <div class="container space-2 space-lg-3">
      <div class="mb-5 text-center">
        <h2>More by beNovelty</h2>
      </div>
      <div class="row w-lg-85 mx-auto" data-aos="fade-up">
        <div class="col-12 col-lg-6 mb-3 mb-lg-0">
          <!-- <a href="/products/fiberconnect" class="card h-100 transition-3d-hover"> -->
          <router-link class="card h-100 transition-3d-hover" :to="{path:'/products/fiberconnect'}">
            <div class="w-sm-65 p-4">
              <h3>FiberConnect</h3>
              <p>One-stop digital offerings for SME / Startups to achieve more in the business world.</p>
              <span>Find Out More<i class="fas fa-angle-right fa-sm ml-2" /></span>
            </div>
            <div class="position-absolute bottom-0 right-0 w-sm-25 max-w-23rem">
              <img class="img-fluid" src="@/assets/svg/icons/icon-22.svg">
            </div>
          </router-link>
          <!-- </a> -->
        </div>
        <div class="col-12 col-lg-6 mb-3 mb-lg-0">
          <!-- <a href="/products/hk-open-banking-sdk" class="card h-100 transition-3d-hover"> -->
          <router-link class="card h-100 transition-3d-hover" :to="{path:'/products/hk-open-banking-sdk'}">
            <div class="w-sm-65 p-4">
              <h3>HK Open Banking SDK</h3>
              <p>Join our Pilot Programme of Open Banking SDK &amp; API today!</p>
              <span>Find Out More<i class="fas fa-angle-right fa-sm ml-2" /></span>
            </div>
            <div class="position-absolute bottom-0 right-0 w-sm-25 max-w-23rem">
              <img class="img-fluid" src="@/assets/svg/icons/icon-3.svg">
            </div>
          </router-link>
          <!-- </a> -->
        </div>
      </div>
    </div>
    <!-- ===== End Other Products Section ===== -->
  </main>

</template>

<script>
const $ = require('jquery')
import HSGoTo from '@/assets/vendor/hs-go-to/src/js/hs-go-to'
import 'bootstrap'
import '@/assets/js/hs.core.js'
import { mapGetters } from 'vuex'
export default {
  name: 'DeveloperPortal',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
    ])
  },
  mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
  },
  created() {
  },
  methods: {
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'API Developer Portal | Products | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/products/api-developer-portal' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'API Developer Portal | Products | beNovelty' },
        { property: 'og:description', content: 'API Developer Portal powered by OpenAPIHub - Create your branded API Developer Portal with ease.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/products/api-developer-portal' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

<style>
.gradient-x-overlay-sm-indigo::before {
  background-image: linear-gradient(45deg, transparent, rgba(2, 158, 248, 0.082));
  background-repeat: repeat-x;
}

.nav-box .nav-link.active {
    color: #57abf0;
    background-color: #f7faff;
    box-shadow: inset 3px 0 0 #57abf0;
    border-radius: 0px 5px 5px 0;
};
</style>
